#header {
  background-color: transparent;
  z-index: 2;
}

@media only screen and (max-width: 620px) {
  .burp-suite-logo {
    display: none;
  }
}

.logos {
  width: 140px;
  object-fit: contain;
  padding-bottom: 5px;
}

.logo-sistema {
  text-align: center;
  width: 170px;
  object-fit: contain;
  padding-bottom: 5px;
}

#login-content {
  padding: 20px;
  flex-wrap: nowrap;
  width: 100%;
}

#main-content {
  padding: 20px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: transparent;
}

#main {
  background-image: url('../../images/login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Mask */
#main:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  height: 100vh;
}

.barra-vertical {
  width: 60px;
  height: 400px;
}

.barra-vertical img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.barra-horizontal {
  display: flex;
  align-content: stretch;
  width: 500px;
  height: 64px;
}

.barra-horizontal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.footer {
  color: white;
  font-weight: bolder;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  padding: 5px 60px;
  width: 100%;
  height: 50px;
  text-align: center;
}
