.react-chat-greeting {
  width: 220px;
  background-color: white;
  padding: 0.5rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.65);
}

.react-chat-close-icon {
  width: 50px !important;
  height: 25px !important;
  margin-bottom: 15px !important;
  fill: rgba(33, 37, 41, 0.45) !important;
  cursor: pointer !important;
  background: #ffffffb5;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chat-close-icon > svg {
  width: 20px !important;
  height: 20px !important;
}

.react-chat-inputBox {
  background-color: #e4e7eb;
}

.react-chat-viewerBox {
  border-radius: 0.5rem;
  border-width: 0 !important;
  border-style: none !important;
}

.react-chat-container,
.react-chat-frame-icon-container,
.react-chat-close-icon {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.65) !important;
}

.react-chat-header {
  background-color: #0071ce;
  padding: 1rem;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.react-chat-header > p {
  padding: 0;
  margin: 0.5rem;
  font-family: sans-serif;
}

.react-chat-header > p:first-child {
  font-size: large;
  font-weight: bold;
}

.react-chat-messageBox {
  max-width: none;
  margin-block: 0.5rem;
}

.react-chat-frame {
  bottom: 80px !important;
  right: 25px !important;
}

@media (max-width: 600px) {
  .react-chat-frame {
    bottom: 0 !important;
    right: 9px !important;
  }
}
